<template>
    <v-container>
        <v-layout class="waarzijnwenu" row wrap align-center>
            <v-flex xs12 lg5 offset-lg1
              offset-xs0 class="secondary--text text-md-center text-lg-left">
                    <p class="display-2"> {{ title }} </p>
                    <div class="headline font-weight-regular">
                        <p> {{ message }} </p>
                        <p class="infopups">{{ infopups }} </p>
                    </div>
            </v-flex>
            <v-flex
            xs12 md6 sm6 lg4 offset-lg1 offset-md3 offset-sm3  offset-xs0 class="text-xs-center">
                <v-img
                  class="imageEvert"
                  width=""
                  :src ="require('@/static/Evert_van_de Werfhorst.jpg')">
                </v-img>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
  data() {
    return {
      title: 'Waar we nu zijn',
      message: `In 1981 kwam ik in aanraking met Mechelse herdershonden en sindsdien ben ik een echte liefhebber van dit ras geworden.
                Na enkele Mechelaars getraind te hebben was ik toe aan een nieuwe uitdaging, ik heb mijn eerste teefje bij Halusetha's gekocht en zij wierp een schitterend nestje.
                Inmiddels heb ik meerdere nestjes gefokt met verschillende eigen teefjes. De pups worden geselecteerd op sociaal gedrag en karakter.`,
      infopups: 'Pups zijn geschikt voor zowel de sport als huishond.',
      image: 'image.jpg',
    };
  },
};
</script>
<style lang="less" scoped>
.waarzijnwenu{
    padding: 25px 25px 25px 25px;

    .infopups{
    margin-top: 15px;
    }

}

</style>
