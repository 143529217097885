<template>
    <v-container id="hoehetbegon">
        <v-layout align-center>
            <v-flex offset-xl2 offset-lg1 offset-xs0 md12 xs12 >
                <div v-if="this.$vuetify.breakpoint.name !== 'xs'">
                    <v-img
                    :min-width="imgwidth"  :max-width="imgwidth"
                    class="img pc" :class="{ desktop: mobile}" v-bind:src="'/img/' + image">
                      <v-flex :class="textbox" class="textbox text-xs-left" >
                          <p class="display-2"> {{ title }} </p>
                          <p class="headline font-weight-regular">{{ message }}</p>
                      </v-flex>
                    </v-img>
                </div>
                <div v-else>
                  <v-flex xs12 class="text-xs-center secondary--text" >
                      <p class="display-2"> {{ title }} </p>
                      <p class="headline font-weight-regular">{{ message }}</p>
                    </v-flex>
                  <v-flex xs12>
                    <v-img class="mobile" v-bind:src="'/img/' + image"></v-img>
                  </v-flex>
            </div>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
  data() {
    return {
      message: `Van jongs af aan heb ik interesse in honden. Vroeger, toen er veel minder verharde wegen waren, gingen mijn broer, onze Duitse herdershond en ik, Evert van de Werfhorst, vaak op               stap. 
        Wij waren dol op de hond en de hond op ons. Ze wilde graag voor ons werken, zo trok ze de hondenkar in haar tuigje of ze verdedigde ons. Zolang ze voor ons mocht werken was onze hond gelukkig. Wij waren één team.`,
      title: 'Hoe het begon',
      image: 'Molen_Overdag.jpg',
    };
  },


  computed: {

    mobile() {
      if (this.$vuetify.breakpoint.name === 'xs') {
        return false;
      }
      return true;
    },
    // removes background class on mobile/tablet
    imgwidth() {
      if (this.$vuetify.breakpoint.lgAndUp) {
        return '850';
      }
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 'auto';
      }
      return 'auto';
    },
    textbox() {
      if (this.$vuetify.breakpoint.xlAndUp) {
        return 'xs12';
      }
      if (this.$vuetify.breakpoint.lgAndUp) {
        return 'xs12';
      }
      if (this.$vuetify.breakpoint.mdAndDown) {
        return 'md';
      }
      return 'mobile;';
    },
  },
};

</script>

<style lang="less" scoped>
    // overflow: visible;
.img{
  overflow-x: visible;
    &.desktop{
      overflow: visible;
    }
}
.textbox{
    width: 760px;
    background-color: white;
    color: #707070;
    overflow-y: visible;
    float: right;
    position: absolute;
    margin-top: 110px;
    margin-left: 650px;
    padding: 20px 30px 20px 30px;
    border: 0.5px solid #707070;

    &.md{
        margin-top: 10%;
        width: 92%;
        margin-left: 4%;
        background-color: rgba(255, 255, 255, 0.527);
      }

    &.lg{
        margin-left: 450px;
    }

    &.mobile{
      width:95%;
      background-color: #70707057;
      color: white;
      overflow-y: visible;
      float: right;
      position: absolute;
      margin: 25px 25px 25px 25px;
      padding: 10px 10px 10px 10px;
      border: 0.5px solid white;

        .display-2{
            font-size:25px!important;
        }

        .headline{
            font-size:16px!important;
        }
    }
    .subline{
        margin-top: 1px;
    }
}
@media (max-width: 1700px) {
    .textbox {
     width: 660px;
    background-color: redd;
    overflow-y: visible;
    float: right;
    position: absolute;
    margin-top: 110px;
    margin-left: 450px;
    padding: 20px 30px 20px 30px;
  }
.img{
  min-width: 560px;
}

  }

</style>
