<template>
  <div class="overOns">
     <Hoehetbegon />
     <Waarzijnwenu />
  </div>
</template>
<script>
import Hoehetbegon from '@/components/OverOns/hoehetbegon.vue';
import Waarzijnwenu from '@/components/OverOns/waarzijnwenu.vue';

export default {
  name: 'About',
  components: {
    Hoehetbegon,
    Waarzijnwenu,
  },
};
</script>

<style>
.logo{
  font-family: 'Roboto Slab', serif;
  color:red;
}
</style>
